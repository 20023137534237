import React from "react";
import { useNavigate } from "react-router-dom";

export interface PropsHandleMenuClick {
  handleMenuClick: () => void;
}
/**
 * 
 * @param param0 
 * @returns jsx
 * menu items for mobile & sidebarMd
 */
const MenuItems = ({ handleMenuClick }: PropsHandleMenuClick) => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col justify-center items-center">
      <div
        className="flex justify-center items-center  p-5 md:pt-10 md:pb-10 w-full cursor-pointer hover:bg-violet-300"
        onClick={() => {
          navigate("/contacts");
          handleMenuClick();
        }}
      >
        <span className="text-2xl mx-3">
          <svg
            stroke="currentColor"
            fill="none"
            strokeWidth="0"
            viewBox="0 0 24 24"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M16 9C16 11.2091 14.2091 13 12 13C9.79086 13 8 11.2091 8 9C8 6.79086 9.79086 5 12 5C14.2091 5 16 6.79086 16 9ZM14 9C14 10.1046 13.1046 11 12 11C10.8954 11 10 10.1046 10 9C10 7.89543 10.8954 7 12 7C13.1046 7 14 7.89543 14 9Z"
              fill="currentColor"
            ></path>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1ZM3 12C3 14.0902 3.71255 16.014 4.90798 17.5417C6.55245 15.3889 9.14627 14 12.0645 14C14.9448 14 17.5092 15.3531 19.1565 17.4583C20.313 15.9443 21 14.0524 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12ZM12 21C9.84977 21 7.87565 20.2459 6.32767 18.9878C7.59352 17.1812 9.69106 16 12.0645 16C14.4084 16 16.4833 17.1521 17.7538 18.9209C16.1939 20.2191 14.1881 21 12 21Z"
              fill="currentColor"
            ></path>
          </svg>
        </span>
        <span className="text-2xl font-thin mx-2">Contacts</span>
      </div>

      <div
        className="flex justify-center items-center  p-5 md:pt-10 md:pb-10   w-full cursor-pointer hover:bg-violet-300 "
        onClick={() => {
          navigate("/dashboard");

          handleMenuClick();
        }}
      >
        <span className="text-2xl mx-3">
          <svg
            stroke="currentColor"
            fill="currentColor"
            strokeWidth="0"
            viewBox="0 0 1024 1024"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M863.1 518.5H505.5V160.9c0-4.4-3.6-8-8-8h-26a398.57 398.57 0 0 0-282.5 117 397.47 397.47 0 0 0-85.6 127C82.6 446.2 72 498.5 72 552.5S82.6 658.7 103.4 708c20.1 47.5 48.9 90.3 85.6 127 36.7 36.7 79.4 65.5 127 85.6a396.64 396.64 0 0 0 155.6 31.5 398.57 398.57 0 0 0 282.5-117c36.7-36.7 65.5-79.4 85.6-127a396.64 396.64 0 0 0 31.5-155.6v-26c-.1-4.4-3.7-8-8.1-8zM951 463l-2.6-28.2c-8.5-92-49.3-178.8-115.1-244.3A398.5 398.5 0 0 0 588.4 75.6L560.1 73c-4.7-.4-8.7 3.2-8.7 7.9v383.7c0 4.4 3.6 8 8 8l383.6-1c4.7-.1 8.4-4 8-8.6z"></path>
          </svg>
        </span>
        <span className="text-2xl font-thin">Dashboard</span>
      </div>
    </div>
  );
};

export default MenuItems;
